<template>
  <div class="flex flex-col space-y-2">
    <pro-input
      label="Nom"
      :placeholder="'Nom'"
      :rules="'required'"
      v-model="item.nom"
      type="text"
    />
    <pro-input
      label="Prénom"
      :placeholder="'Prénom'"
      :rules="'required'"
      v-model="item.prenom"
      type="text"
    />
    <div class="flex flex-col space-y-[6px]">
      <label class="flex justify-between font-medium text-promy-gray-200">
        Adresse
      </label>
    </div>
    <pro-auto-complete
      is_auto_complete_cross_api
      placeholder="Recherche"
      bordered
      :list="autocomplete_items"
      v-model="item.adresse.label"
      :args="['properties', 'label']"
      @setItem="setItemForAutocomplete($event)"
      :is_loading="is_autocomplete_fetching"
      :is_searchable="true"
      :rules="'required'"
      typeInput="tunnel"
    />
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      AddressIsSelecting: false,
      is_autocomplete_fetching: false,
      autocomplete_items: [],
    }
  },
  methods: {
    setItemForAutocomplete(adresse) {
      this.AddressIsSelecting = true
      this.item.adresse = {
        label: adresse.properties.label,
        long: adresse.geometry.coordinates[0],
        lat: adresse.geometry.coordinates[1],
        code_insee: adresse.properties.citycode,
      }
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      800, // 800 milliseconds
    ),
    async fetchAdresses() {
      this.is_autocomplete_fetching = true
      let response = await this.$http.get('grand-publics/gouv-url-reveler', {
        params: {
          limit: 20,
          q: this.item.adresse.label,
        },
      })
      this.autocomplete_items = []
      if (response.data.hasOwnProperty('features')) {
        this.autocomplete_items = response.data.features
      }
      this.is_autocomplete_fetching = false
    },
  },
  watch: {
    'item.adresse.label': {
      handler() {
        if (!this.AddressIsSelecting) {
          this.searchAfterDebounce()
        }

        this.AddressIsSelecting = false
      },
    },
  },
}
</script>